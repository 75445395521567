import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

interface ScrollTarget extends Element {
  dataset: {
    contentName: string;
  };
}

@TypedController
export default class extends Controller {
  static targets = ["navigationItem"];

  declare readonly navigationItemTargets: HTMLElement[];

  connect() {
    this.navigationItemTargets.forEach((item) => {
      item.addEventListener("click", this.handleClick.bind(this));
    });
  }

  handleClick(event: Event) {
    event.preventDefault();

    const clickedItem = event.currentTarget as HTMLElement;
    const anchor = clickedItem.getAttribute("data-anchor");

    if (!anchor) return;

    const targetElement = document.querySelector(
      `[data-content-name="${anchor}"]`
    ) as ScrollTarget;

    if (targetElement) {
      // Get the header height and scoll to element
      const header = document.querySelector("header");
      const headerHeight = header ? header.offsetHeight : 0;
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      const activeClass =
        'after:tw-absolute after:tw-left-0 after:tw-right-11 after:tw-bottom-2 after:tw-content-[""] after:tw-h-1 after:tw-rounded-full after:tw-bg-[var(--brand-main-nav-item-background-color-hover)]';

      // Remove the class from all items first
      this.navigationItemTargets.forEach((item) => {
        item.className = item.className.replace(activeClass, "").trim();
      });

      // Add the class to the currently clicked item
      clickedItem.className += " " + activeClass;
    }
  }
}
